<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <!-- Multiple Column -->
    <v-col cols="12">
      <v-card-text>
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                block
                dark
                large
                :loading="loading"
                @click="save()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-tabs v-model="pos">
                <v-tab @click="setPosCotizador(0)">
                  {{ $t('lbl.infoFiscal') }}
                </v-tab>
                <v-tab @click="setPosCotizador(1)">
                  {{ $t('lbl.bancaria') }}
                </v-tab>
                <v-tab @click="setPosCotizador(2)">
                  {{ $t('menu.reservas') }}
                </v-tab>
                <v-tab @click="setPosCotizador(3)">
                  {{ $t('lbl.identificador') }}
                </v-tab>
              </v-tabs>

              <v-divider></v-divider>

              <v-tabs-items v-model="pos">
                <v-tab-item>
                  <InfoFiscal
                    :tipos_fiscal="tipos_fiscal"
                    :countries="countries"
                  />
                </v-tab-item>
                <v-tab-item>
                  <Bancaria
                    :pay-ments="payMents"
                    :monedas="monedas"
                    :countries="countries"
                  />
                </v-tab-item>
                <v-tab-item>
                  <Codes :model="model" />
                </v-tab-item>
                <v-tab-item>
                  <v-row class="mt-2">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-text-field
                            v-model="model.name"
                            class="pb-5"
                            :label="$t('lbl.name')"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-img
                            v-if="previewCunno"
                            contain
                            class="justify-center pb-5"
                            :src="previewCunno"
                            max-height="100px"
                          ></v-img>
                          <v-file-input
                            v-model="cunno"
                            accept=".png,.jpg,.jpeg,.webp,.bmp,.gif"
                            show-size
                            outlined
                            dense
                            label="Cuño y firma para la papelería (Cotizaciones, Facturas), etc"
                            hide-details
                            @change="setCunno"
                            @click:clear="clearCunno"
                          ></v-file-input>
                        </v-col>

                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-color-picker
                            v-model="model.color"
                            :hide-canvas="true"
                            :hide-inputs="false"
                            :hide-mode-switch="true"
                            mode="hexa"
                            :show-swatches="false"
                            width="100%"
                          ></v-color-picker>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-img
                            v-if="previewImage"
                            contain
                            class="justify-center pb-5"
                            :src="previewImage"
                            max-height="100px"
                          ></v-img>
                          <v-file-input
                            v-model="avatar"
                            accept=".png,.jpg,.jpeg,.webp,.bmp,.gif"
                            show-size
                            outlined
                            dense
                            label="Logo"
                            hide-details
                            @change="setAvatar"
                            @click:clear="clearAvatar"
                          ></v-file-input>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-img
                            v-if="previewIsotipo"
                            contain
                            class="justify-center pb-5"
                            :src="previewIsotipo"
                            max-height="100px"
                          ></v-img>
                          <v-file-input
                            v-model="isotipo"
                            accept=".png,.jpg,.jpeg,.webp,.bmp,.gif"
                            show-size
                            outlined
                            dense
                            label="Isotipo"
                            hide-details
                            @change="setIsotipo"
                            @click:clear="clearIsotipo"
                          ></v-file-input>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-img
                            v-if="previewFavicon"
                            contain
                            class="justify-center pb-5"
                            :src="previewFavicon"
                            width="100%"
                            max-height="50px"
                          ></v-img>
                          <v-file-input
                            v-model="favicon"
                            accept=".ico"
                            show-size
                            outlined
                            dense
                            label="Favicon"
                            hide-details
                            @change="setFavicon"
                            @click:clear="clearFavicon"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiInformation,
} from '@mdi/js'
import moment from 'moment'
import {
  TiptapVuetify,
  Bold,
  Italic,

  // BulletList,
  // OrderedList,
  ListItem,
  OrderedList,
  BulletList,
  Underline,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'
import { v4 as uuidv4 } from 'uuid'

import InfoFiscal from '../utils/InfoFiscal.vue'
import Bancaria from '../utils/Bancaria.vue'
import Codes from '../utils/Codes.vue'

// import CtasBancarias from '../utils/CtasBancarias.vue'

export default {
  components: {
    // Email,
    // CtasBancarias,
    // eslint-disable-next-line vue/no-unused-components
    TiptapVuetify,
    InfoFiscal,
    Bancaria,
    Codes,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      extensions: [Italic, Bold, HardBreak, ListItem, OrderedList, BulletList, Underline],
      isLoading: true,
      avatar: null,
      previewImage: null,
      isotipo: null,
      previewIsotipo: null,
      favicon: null,
      previewFavicon: null,
      cunno: null,
      previewCunno: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiInformation,
      },
      model: {},
      id_model: -1,
      permisos: [],
      loading: false,
      tipos_fiscal: [],
      tipos_cuentas_bancarias: [],
      monedas: [],
      countries: [],
      paisUser: {},
      pos: 0,
      payMents: [],
      swiftRules: [
        // v => !!v || 'Campo requerido',

        // v => v.length <= 8 || 'Swift no válido',
        v => /[0-9a-zA-Z]{8}/.test(v) || 'Swift no válido',
      ],
      pay_medios: [
        {
          id: uuidv4(),
          payments_id: 0,
          payments: 'banco',
          is_new: true,
          name_fiscal_facturation: null,
          no_cuenta_bancaria: null,
          moneda: null,
          swift: null,
          iban: null,
          name_banco: null,
          code_sucursal: null,
          address: [
            {
              address: null,
            },
          ],
          code_postal: null,
          localidad: null,
          country: null,
          principal: false,
          dataZelle: {
            email: null,
            phone: null,
          },
          dataWise: null,
          dataPaypal: null,
          dataRedsys: null,
          dataSIBS: null,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      emailsReserve: state => state.app.emailsReserve,
      ctasBancarias: state => state.app.ctasBancarias,
      dataBancaria: state => state.app.dataBancaria,
    }),
    code() {
      let sig = 'PPP'
      if (this.model.siglas) {
        sig = this.model.siglas
      }
      const dat = moment(new Date()).format('DDMMYY')
      let sigV = 'VV'
      if (this.model.siglas_flights) {
        sigV = this.model.siglas_flights
      }

      return `${sig}-${dat}${sigV}${Math.random()
        .toString(36)
        .slice(-3)
        .toUpperCase()}`
    },
  },
  created() {
    this.tipos_fiscal.push('NIT')
    this.tipos_fiscal.push('NIF')
    this.tipos_fiscal.push('RTN')
    this.tipos_fiscal.push('RUC')
    this.tipos_fiscal.push('RFC')
    this.tipos_fiscal.push('RUT')
    this.tipos_fiscal.push('EIN')

    this.tipos_fiscal.sort()

    this.tipos_cuentas_bancarias.push('Ahorros')
    this.tipos_cuentas_bancarias.push('Corriente')
    this.payMents.push({
      icon: 'mdi-bank-transfer',
      1: 1,
      name: 'Banco',
      slug: 'banco',
    })
    this.payMents.push({
      icon: 'mdi-alpha-z-box-outline',
      1: 2,
      name: 'Zelle',
      slug: 'zelle',
    })
    this.payMents.push({
      icon: 'mdi-card-bulleted-outline',
      1: 3,
      name: 'Tarjeta',
      slug: 'tarjeta-debito-credito',
    })

    // this.getItem()

    this.loadPermisos()
    this.getCountries()
    this.getCurrencies()
  },
  methods: {
    ...mapMutations([
      'addEmailAdminReserve',
      'updateEmailAdminReserve',
      'addCtasBancarias',
      'updateCtasBancarias',
      'setPosCotizador',
      'updateDataBancaria',
    ]),
    getDataUser() {
      /* this.axios
        .get('https://ipapi.co/json')
        .then(res => {
          this.paisUser = res.data
          this.model.data_country_contact = this.paisUser
          this.model.prefijo_number_contact = this.paisUser.country_calling_code
          this.model.prefijo_number_facturation = this.paisUser.country_calling_code
          this.model.country_facturation = this.paisUser.country_name
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getCountries()
        }) */

      this.getCountries()
    },
    getCountries() {
      this.axios.get('country_list?per_page=1000').then(res => {
        this.countries = res.data.data
        this.countries.forEach(element => {
          if (this.paisUser.country_code) {
            if (element.code === this.paisUser.country_code) {
              this.model.country_facturation = element.name
            }
          }

          /* if (element.currencies) {
              element.currencies.forEach(mmm => {
                if (!this.monedas.includes(mmm.code)) {
                  this.monedas.push(mmm.code)
                }
              })
            } */
        })
      })

      /* .finally(() => {
          this.getCurrencies()
        }) */
    },
    getCurrencies() {
      this.axios
        .get('currency_list?per_page=10000')
        .then(res => {
          this.monedas = res.data.data
        })

        .finally(() => {
          this.getItem()
        })
    },
    getItem() {
      this.axios
        .get('configuration/data-general', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.model = res.data.data.data.data
            this.id_model = res.data.data.data.id

            if (this.model.logo) {
              this.previewImage = `${this.rutaPublic}${this.model.logo}`
            }
            if (this.model.isotipo) {
              this.previewIsotipo = `${this.rutaPublic}${this.model.isotipo}`
            }
            if (this.model.favicon) {
              this.previewFavicon = `${this.rutaPublic}${this.model.favicon}`
            }
            if (this.model.cunno) {
              this.previewCunno = `${this.rutaPublic}${this.model.cunno}`
            }

            if (this.model.emails_noty_reserve) {
              if (this.model.emails_noty_reserve.length > 0) {
                this.updateEmailAdminReserve(this.model.emails_noty_reserve)
              } else {
                this.updateEmailAdminReserve([])
              }
            } else {
              this.updateEmailAdminReserve([])
            }

            if (this.model.data_bancaria) {
              if (!this.model.data_bancaria.info_fiscal) {
                this.model.data_bancaria.info_fiscal = {
                  address: [
                    {
                      address: null,
                    },
                  ],
                  code_postal: null,
                  localidad: null,
                  country: null,
                }
              }

              // this.dataBancaria = this.model.data_bancaria
              this.updateDataBancaria(this.model.data_bancaria)
            }

            if (this.model.ctas_bancarias && this.model.ctas_bancarias.length > 0) {
              this.updateCtasBancarias(this.model.ctas_bancarias)
            } else {
              this.updateCtasBancarias(this.pay_medios)
            }
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false
          }, 100)
        })
    },
    loadPermisos() {
      this.axios
        .get('permiso?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(per => {
          this.permisos = per.data.data
        })
    },
    setAvatar() {
      if (this.avatar) {
        this.previewImage = URL.createObjectURL(this.avatar)
      }
    },
    clearAvatar() {
      this.avatar = null
      this.previewImage = null
    },
    setIsotipo() {
      if (this.isotipo) {
        this.previewIsotipo = URL.createObjectURL(this.isotipo)
      }
    },
    clearIsotipo() {
      this.isotipo = null
      this.previewIsotipo = null
    },
    setFavicon() {
      if (this.favicon) {
        this.previewFavicon = URL.createObjectURL(this.favicon)
      }
    },
    clearFavicon() {
      this.favicon = null
      this.previewFavicon = null
    },
    setCunno() {
      if (this.cunno) {
        this.previewCunno = URL.createObjectURL(this.cunno)
      }
    },
    clearCunno() {
      this.cunno = null
      this.previewCunno = null
    },
    save() {
      if (
        this.model.name
        && this.model.color
        && this.model.siglas
        && this.model.siglas_flights
        && this.model.siglas_cars
        && this.model.siglas_hotel
        && this.model.siglas_transfers
        && this.model.siglas_excursions
        && this.model.siglas_packages
        && this.model.siglas_cruise
      ) {
        this.loading = true
        const formData = new FormData()
        if (this.avatar) {
          formData.append('avatar', this.avatar)
        }
        if (this.isotipo) {
          formData.append('isotipo', this.isotipo)
        }
        if (this.favicon) {
          formData.append('favicon', this.favicon)
        }
        if (this.cunno) {
          formData.append('cunno', this.cunno)
        }

        let proceder = true

        const newCtasBancarias = []
        this.ctasBancarias.forEach(elementCB => {
          if (elementCB.payments === 'tarjeta-debito-credito') {
            if (
              !elementCB.dataCards.name
              || !elementCB.dataCards.number
              || !elementCB.dataCards.date_expire
              || !elementCB.dataCards.cvv
            ) {
              proceder = false
            }
          }
        })
        if (proceder) {
          this.ctasBancarias.forEach(elementCB => {
            newCtasBancarias.push({
              ...elementCB,
              is_new: false,
            })
          })
          this.updateCtasBancarias(newCtasBancarias)
        }

        // console.log(newCtasBancarias)

        const json = JSON.stringify({
          name: this.model.name,
          color: this.model.color,
          siglas: this.model.siglas,
          siglas_flights: this.model.siglas_flights,
          siglas_cars: this.model.siglas_cars,
          siglas_hotel: this.model.siglas_hotel,
          siglas_transfers: this.model.siglas_transfers,
          siglas_excursions: this.model.siglas_excursions,
          siglas_packages: this.model.siglas_packages,
          siglas_cruise: this.model.siglas_cruise,
          emails_noty_reserve: this.emailsReserve,
          addressB2BStreep: this.model.addressB2BStreep,
          addressB2BNoApto: this.model.addressB2BNoApto,
          addressB2BCity: this.model.addressB2BCity,
          addressB2BCodePostal: this.model.addressB2BCodePostal,
          addressB2BCountry: this.model.addressB2BCountry,
          ctas_bancarias: this.ctasBancarias,

          // DATA BANCARIA
          data_bancaria: {
            tipo_fiscal: this.dataBancaria.tipo_fiscal,
            cif_nif_facturation: this.dataBancaria.cif_nif_facturation,
            info_fiscal: this.dataBancaria.info_fiscal,

            /* name_fiscal_facturation: this.dataBancaria.name_fiscal_facturation,
            no_cuenta_bancaria: this.dataBancaria.no_cuenta_bancaria,
            tipo_cuenta_bancaria: this.dataBancaria.tipo_cuenta_bancaria,
            moneda: this.dataBancaria.moneda,
            name_banco: this.dataBancaria.name_banco,
            swift: this.dataBancaria.swift,
            code_sucursal: this.dataBancaria.code_sucursal,
            direction_facturation: this.dataBancaria.direction_facturation,
            localidad_facturation: this.dataBancaria.localidad_facturation,
            country_facturation: this.dataBancaria.country_facturation, */
          },
        })

        // VERIFICAR Q NO SE SUBA UN EMAIL VACIO
        if (this.emailsReserve.length > 0) {
          this.emailsReserve.forEach(elementEmail => {
            if (!elementEmail.email) {
              proceder = false
            }
          })
        }

        if (proceder) {
          formData.append('data', json)

          this.axios
            .post('configuration/data-general', formData, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                window.location.reload()
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.loading = false
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
