<template>
  <fragment>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="card.name"
          :label="$t('lbl.cardName')"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-if="card.is_new"
          v-model="card.number"
          :label="$t('lbl.cardNumber')"
          outlined
          dense
          type="number"
          hide-details="auto"
          :rules="cardsRules"
          append-outer-icon="mdi-card-text-outline"
          @input="detectTypeCard"
        >
          <template v-slot:append-outer>
            <Card
              v-if="!typeCard"
              :height="40"
              :width="40"
              class="card_new"
            />
            <Mastercard
              v-else-if="typeCard === 'mastercard'"
              :height="40"
              :width="40"
              class="card_new"
            />
            <AmericanExpress
              v-else-if="typeCard === 'american-express'"
              :height="40"
              :width="40"
            />
            <Dankort
              v-else-if="typeCard === 'dankort'"
              :height="40"
              :width="40"
            />
            <Diners
              v-else-if="typeCard === 'diners'"
              :height="40"
              :width="40"
            />
            <Discover
              v-else-if="typeCard === 'discover'"
              :height="40"
              :width="40"
            />
            <Jcb
              v-else-if="typeCard === 'jcb'"
              :height="40"
              :width="40"
            />
            <Visa
              v-else-if="typeCard === 'visa'"
              :height="40"
              :width="40"
            />
            <VisaElectron
              v-else-if="typeCard === 'visa-electron'"
              :height="40"
              :width="40"
            />
            <Maestro
              v-else-if="typeCard === 'maestro'"
              :height="40"
              :width="40"
            />
            <UnionPay
              v-else-if="typeCard === 'unionpay'"
              :height="40"
              :width="40"
            />
            <!--<img
                v-else
                width="24"
                height="24"
                src="https://cdn.vuetifyjs.com/images/logos/v-alt.svg"
                alt=""
              />-->
          </template>
        </v-text-field>
        <v-text-field
          v-else
          :label="$t('lbl.cardNumber')"
          outlined
          dense
          hide-details="auto"
          append-outer-icon="mdi-card-text-outline"
          :value="`XXXXXXXXX${card.number.slice(-4)}`"
          disabled
        >
          <template v-slot:append-outer>
            <!--<v-progress-circular
                v-if="loading"
                size="24"
                color="info"
                indeterminate
              ></v-progress-circular>-->
            <Card
              v-if="!typeCard"
              :height="40"
              :width="40"
              class="card_new"
            />
            <Mastercard
              v-else-if="typeCard === 'mastercard'"
              :height="40"
              :width="40"
              class="card_new"
            />
            <AmericanExpress
              v-else-if="typeCard === 'american-express'"
              :height="40"
              :width="40"
            />
            <Dankort
              v-else-if="typeCard === 'dankort'"
              :height="40"
              :width="40"
            />
            <Diners
              v-else-if="typeCard === 'diners'"
              :height="40"
              :width="40"
            />
            <Discover
              v-else-if="typeCard === 'discover'"
              :height="40"
              :width="40"
            />
            <Jcb
              v-else-if="typeCard === 'jcb'"
              :height="40"
              :width="40"
            />
            <Visa
              v-else-if="typeCard === 'visa'"
              :height="40"
              :width="40"
            />
            <VisaElectron
              v-else-if="typeCard === 'visa-electron'"
              :height="40"
              :width="40"
            />
            <Maestro
              v-else-if="typeCard === 'maestro'"
              :height="40"
              :width="40"
            />
            <UnionPay
              v-else-if="typeCard === 'unionpay'"
              :height="40"
              :width="40"
            />
            <!--<img
                v-else
                width="24"
                height="24"
                src="https://cdn.vuetifyjs.com/images/logos/v-alt.svg"
                alt=""
              />-->
          </template>
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="card.date_expire"
          :label="$t('lbl.cardExpire')"
          outlined
          dense
          placeholder="MM/YYYY"
          hide-details="auto"
          :rules="expireRules"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="card.cvv"
          label="CVV"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-switch
          v-model="card.principal"
          :label="$t('lbl.principal')"
          hide-details
          class="mt-0"
          :disabled="disabledRadio"
        ></v-switch>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import Visa from '@/views/utils/cards/visa.vue'
// eslint-disable-next-line import/no-unresolved
import Mastercard from '@/views/utils/cards/mastercard.vue'
// eslint-disable-next-line import/no-unresolved
import AmericanExpress from '@/views/utils/cards/americanexpress.vue'
// eslint-disable-next-line import/no-unresolved
import Dankort from '@/views/utils/cards/dankort.vue'
// eslint-disable-next-line import/no-unresolved
import Diners from '@/views/utils/cards/diners.vue'
// eslint-disable-next-line import/no-unresolved
import Discover from '@/views/utils/cards/discover.vue'
// eslint-disable-next-line import/no-unresolved
import Jcb from '@/views/utils/cards/jcb.vue'
// eslint-disable-next-line import/no-unresolved
import VisaElectron from '@/views/utils/cards/visaelectron.vue'
// eslint-disable-next-line import/no-unresolved
import Maestro from '@/views/utils/cards/maestro.vue'
// eslint-disable-next-line import/no-unresolved
import UnionPay from '@/views/utils/cards/unionpay.vue'
// eslint-disable-next-line import/no-unresolved
import Card from '@/views/utils/cards/card.vue'

export default {
  components: {
    Visa,
    Mastercard,
    AmericanExpress,
    Dankort,
    Diners,
    Discover,
    Jcb,
    VisaElectron,
    Maestro,
    UnionPay,
    Card,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    card: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      rols: [],
      products: [],
      emailRules: [v => this.isEmail(v) || 'Email debe ser válido'],
      cardsRules: [v => this.isValidCard(v) || 'La tarjeta debe ser válida'],
      expireRules: [v => this.isValidExpire(v) || 'La fecha debe ser válida'],
      typeCard: null,
    }
  },
  computed: {
    ...mapState({
      ctasBancarias: state => state.app.ctasBancarias,
    }),
    disabledRadio() {
      let tienePrincipal = false
      this.ctasBancarias.forEach(element => {
        if (element.dataCards) {
          if (element.dataCards.principal) {
            tienePrincipal = true
          }
        }
      })

      let disabled = true
      if (tienePrincipal) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.ctasBancarias.length; index++) {
          const element = this.ctasBancarias[index]

          if (element.dataCards) {
            if (element.dataCards.principal && element.dataCards.number === this.card.number) {
              disabled = false
            }
          }
        }
      } else {
        disabled = false
      }

      return disabled
    },
  },
  created() {
    this.detectTypeCard()
  },
  methods: {
    ...mapMutations(['deleteEmailAdminReserve']),
    isValidCard(str) {
      const res = /^(\d?){15,16}$/
      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        if (res.test(str)) {
          return true
        }

        return false
      } catch (_) {
        return false
      }
    },
    isValidExpire(str) {
      // const res = /^(\d?){15,16}$/
      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        const reg = new RegExp('(((0[123456789]|10|11|12)/(([1][9][0-9][0-9])|([2][0-9][0-9][0-9]))))')

        if (reg.test(str)) return true

        return false
      } catch (_) {
        return false
      }
    },
    detectTypeCard() {
      this.loading = true
      let findType = false

      // visa
      let re = new RegExp('^4')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'visa'
        findType = true
      }

      // Mastercard
      // Updated for Mastercard 2017 BINs expansion
      if (
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
          this.card.number,
        )
      ) {
        this.typeCard = 'mastercard'
        findType = true
      }

      // American express
      re = new RegExp('^3[47]')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'american-express'
        findType = true
      }

      // Discover
      re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'discover'
        findType = true
      }

      // Diners
      re = new RegExp('^36')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'diners'
        findType = true
      }

      // Diners
      re = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'diners'
        findType = true
      }

      // Diners - Carte Blanche
      re = new RegExp('^30[0-5]')
      if (this.card.number.match(re) != null) {
        // this.typeCard = 'diners-carte-blanche'
        // findType = true
      }

      // JCB
      re = new RegExp('^35(2[89]|[3-8][0-9])')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'jcb'
        findType = true
      }

      // JCB
      re = new RegExp('^(?:2131|1800|35)[0-9]{0,}$')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'jcb'
        findType = true
      }

      // JCB
      re = new RegExp('^30')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'jcb'
        findType = true
      }

      // Visa Electron
      re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'visa-electron'
        findType = true
      }

      // Maestro
      re = new RegExp('^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'maestro'
        findType = true
      }

      // Maestro
      re = new RegExp('^(5[06789]|6)[0-9]{0,}')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'maestro'
        findType = true
      }

      // Dankort
      re = new RegExp('^(5019)')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'dankort'
        findType = true
      }

      // Interpayment
      re = new RegExp('^(636)')
      if (this.card.number.match(re) != null) {
        // this.typeCard = 'interpayment'
        // findType = true
      }

      // Unionpay
      re = new RegExp('^(62|88)')
      if (this.card.number.match(re) != null) {
        this.typeCard = 'unionpay'
        findType = true
      }

      if (!findType) {
        this.typeCard = null
      }

      this.loading = false
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
::v-deep .v-input__append-outer {
  margin-top: 0px !important;
}
</style>
